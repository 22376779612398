import { Button, Paper, TextField } from '@mui/material';
import logo from 'assets/img/logo_sm.png';
import logoText from 'assets/img/logo_text.png';
import { ENV } from 'common/enum/enums';
import { FeedbackFormRrequest } from 'common/type/type';
import { Done } from 'components/feedback-form/done';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DEFAULT_FEEDBACK_MES } from './common';
import styles from './style.module.scss';

const FeedbackForm: FC = () => {

  const [done, setDone] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FeedbackFormRrequest>({
    defaultValues: DEFAULT_FEEDBACK_MES,
  });

  const chatId = [472469772, 929453530];
  const token = ENV.BOT_TOKEN;

  const url = `https://api.telegram.org/bot${token}/sendMessage`;

  const onSubmit = (payload: FeedbackFormRrequest): void => {

    setDone(true);
    chatId.forEach((it) => {
      const data = {
        chat_id: it,
        text: payload.feedbackMessage,
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    });

  };

  if(done) {
    return (
      <Done />
    );
  }

  return (
    <div style={{
      backgroundColor: '#e1f5fe',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Paper style={{ width: '90vw', borderRadius: 15 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 15 }}>
            <div className={styles.logo}>
              <img src={logo} alt="user icon"/>
            </div>
            <img style={{ width: '80vw', paddingBottom: 15 }} src={logoText} alt="user icon"/>
            <TextField

              {...register('feedbackMessage',
                {
                  required: 'Неможливо надіслати порожнє повідомлення',
                })}
              placeholder="Залиште свій відгук"
              rows="5"
              fullWidth={true}
              name="feedbackMessage"
              id="outlined-basic"
              variant="outlined"
              multiline={true}
              error={Boolean(errors.feedbackMessage)}
              helperText={errors.feedbackMessage?.message}
            />
            <Button
              style={{ marginTop: 15, width: '50%' }}
              variant="contained"
              type="submit"
            >Надіслати</Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export { FeedbackForm };
