import { Paper, Typography } from '@mui/material';
import { Icon } from 'components/icon/icon';
import { FC } from 'react';

import styles from './style.module.scss';

const Done: FC = () => {

  return (
    <div style={{
      backgroundColor: '#f1f8e9',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Paper style={{ width: '70vw' }} >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Icon
            name={'done'}
            className={styles.icon}
          />
          <Typography className={styles.textTitle} variant="h4">ДЯКУЄМО</Typography>
          <Typography className={styles.textSubTitle}>Ми намагаємося стати кращими</Typography>
        </div>
      </Paper>
    </div>
  );
};

export { Done };
