import { ReactComponent as Done } from 'assets/icons/done.svg';
import { SvgIconName } from 'common/type/type';
import { getValidClasses } from 'helpers/helpers';
import { FC, SVGProps } from 'react';

import styles from './styles.module.scss';

const iconNameToIcon: Record<SvgIconName, FC<SVGProps<SVGSVGElement>>> = {
  done: Done,
};

type Props = {
  name: SvgIconName;
  className?: string;
};

const Icon: FC<Props> = ({ name, className }) => {
  const SelectedIcon = iconNameToIcon[name];

  return <SelectedIcon className={getValidClasses(styles.icon, className)} />;
};

export { Icon };
